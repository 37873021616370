export const LANG_FALLBACK = 'fallback'

export const LANG_LOCALES = {
	pl: 'pl-PL', // Polish
	en: 'en-US', // English
	uk: 'uk-UA', // Ukrainian
	cs: 'cs-CZ'  // Czech
}

// {pl: 'pl', en: 'en'.... } language ENUM:
export const LANGUAGES = Object.keys(LANG_LOCALES).reduce((aggregator, lang) => {
	aggregator[lang] = lang;
	return aggregator;
}, {})
