import { TControl } from '@/templating/TControl'
import { makeDiscountBadge, makeStandardBadge } from '@/templating/badge-rederer'

const SPECIAL_TYPE_DISCOUNT = 'discount';
const AVERAGE_BADGE_HEIGHT = 25;

const LOWEST_PRICE_IN_30_DAYS_INFO = {
	pl: '* Najniższa cena oferty z 30 dni przed obniżką', // cena z 30 dni
	en: '* The lowest price from 30 days before the discount', // price from 30 days
	cs: '* Nejnižší cena nabídky za posledních 30 dnů před snížením ceny', // cena za 30 dnů
}

export class TemplateBadges extends TControl {

	static $selector = '[data-badges]';
	static $foreignProductNameSelector = '[data-product-name]';
	static $lowestPriceClassName = 'label__lowest_price_info';
	badges = [];
	langToken = 'pl'

	constructor (ref) {
		super(ref, 'badges')
		this.id = 'badges'
	}

	set list(badges) {
		this.badges = badges;
		this.mapValuesToHTML();
		this.makeLowestPriceText();
	}

	get list() {
		return this.badges;
	}

	set lang(name) {
		this.changeLanguage(name);
	}

	get lang() {
		return this.langToken || 'pl';
	}

	updateBadge({id, ...values} = {}) {
		const badge = this.badges.find(b => b.id === id);
		if(badge)  {
			Object.assign(badge, values);
			this.mapValuesToHTML();
			this.makeLowestPriceText();
		}
	}

	mapValuesToHTML() {
		if(Array.isArray(this.badges)) {
			this.ref.innerHTML = '';
			const badgesWithoutDiscount = this.badges.filter(b => b?.type !== SPECIAL_TYPE_DISCOUNT);
			const specialDiscountBadges = this.badges.filter(b => b?.type === SPECIAL_TYPE_DISCOUNT);
			[
				...specialDiscountBadges,
				...badgesWithoutDiscount
			].forEach((badge) => {
				const div = document.createElement('div');
				div.innerHTML = this.renderBadge(badge);
				this.ref.prepend(div)
			})
			// @Business: discount badges should be always above the price
			// for now, we just check if badges box is squashed (approx 25px) - if so - reorder above:
			this.swapOrderForSquashedBadgeField();
		}
	}

	makeLowestPriceText() {
		// assure to remove existing text:
		const $lowestPriceInfo = this.ref.offsetParent?.querySelector(`.${TemplateBadges.$lowestPriceClassName}`);
		$lowestPriceInfo?.remove();
		// add text only if needed:
		const hasSpecialDiscountBadge = this.badges.some(b => b?.type === SPECIAL_TYPE_DISCOUNT);
		if(hasSpecialDiscountBadge) {
			const textContent = LOWEST_PRICE_IN_30_DAYS_INFO[this.langToken] || LOWEST_PRICE_IN_30_DAYS_INFO.en;
			const $productNameBox = this.ref.offsetParent?.querySelector(TemplateBadges.$foreignProductNameSelector);
			if($productNameBox) {
				const div = document.createElement('div');
				div.className = TemplateBadges.$lowestPriceClassName
				div.textContent = textContent
				$productNameBox.after(div)
			}
		}
	}

	renderBadge( {id, type, label, label_two, background, icon_url, color} = {}) {
		if(type === SPECIAL_TYPE_DISCOUNT) {
			return makeDiscountBadge({id, label, label_two}, this.lang);
		}
		return makeStandardBadge({id, background, icon_url, label, color})
	}

	/*
	* This is a work-around for now, for bug: e095efd44000 (v2)
	* */
	swapOrderForSquashedBadgeField() {
		// note: requestAnimationFrame is wrong choice here [!!] we need a task.
		setTimeout(() => {
			const parsedHeight = parseInt(getComputedStyle(this.ref).height);
			const badgesDivHeightIsLikeOneBadge = parsedHeight <= AVERAGE_BADGE_HEIGHT;
			if(!isNaN(parsedHeight) && badgesDivHeightIsLikeOneBadge) {
				const items = this.ref.querySelectorAll('.badges__item--discount')
				this.ref.prepend(...items)
			}
		})
	}
}
