<template>
    <div class="locale-picker">
        <label v-if="!readOnly">
            <template v-if="showLabel">Language:</template>
            <a-select
                class="locale-picker__select"
                @change="$emit('input', chosenLang)"
                v-model="chosenLang"
            >
                <a-select-option
                    v-for="{ name, value, flag } of locales"
                    :value="value"
                    :key="value"
                >
                    <img
                        class="locale-picker__flag"
                        :alt="name + ' flag image'"
                        :src="flag"
                    />
                    <span
                        class="locale-picker__text"
                        v-if="TYPES.FULL === displayType"
                        >{{ name }}</span
                    >
                </a-select-option>
            </a-select>
        </label>
        <div v-else>
            <template v-if="showLabel">Language: </template>
            <img
                class="locale-picker__flag locale-picker__flag--small"
                :alt="chosenLocale.value + ' flag image'"
                :src="chosenLocale.flag"
            />
            <span
                class="locale-picker__text"
                v-if="TYPES.FULL === displayType"
                >{{ chosenLocale.name }}</span
            >
        </div>
    </div>
</template>

<script>
import { LANG_LOCALES } from '@/components/shared/supported-languages'
import plFlag from 'flag-icon-css/flags/4x3/pl.svg'
import enFlag from 'flag-icon-css/flags/4x3/gb.svg'
import uaFlag from 'flag-icon-css/flags/4x3/ua.svg'
import czFlag from 'flag-icon-css/flags/4x3/cz.svg'
import xxFlag from '@/assets/empty-flag-xx.svg'

const TYPES = {
    FULL: 'full',
    FLAG_ONLY: 'flag-only',
}

const VALUE_RANGES = {
    LANG_ONLY: 'lang-only',
    LANG_AND_LOCALE: 'lang-and-locale',
}

export default {
    name: 'LocalePicker',
    props: {
        showLabel: {
            type: Boolean,
            default: true,
        },
        showEmptyPick: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        displayType: {
            type: String,
            default: TYPES.FULL,
        },
        valueRange: {
            type: String,
            default: VALUE_RANGES.LANG_AND_LOCALE,
        },
        value: {
            type: String,
            default: LANG_LOCALES.pl,
        },
    },
    data() {
        return {
            chosenLang: LANG_LOCALES.pl,
        }
    },
    computed: {
        chosenLocale() {
            return (
                this.locales.find(({ value }) => value === this.chosenLang) ||
                {}
            )
        },
        locales() {
            const picks = [
                { name: 'Polish', value: LANG_LOCALES.pl, flag: plFlag },
                { name: 'English', value: LANG_LOCALES.en, flag: enFlag },
                { name: 'Ukrainian', value: LANG_LOCALES.uk, flag: uaFlag },
                { name: 'Czech', value: LANG_LOCALES.cs, flag: czFlag },
            ].map(this.valueMapper)

            if (this.showEmptyPick) {
                picks.unshift({ name: '---', value: '', flag: xxFlag })
            }

            return picks
        },
        TYPES: () => TYPES,
    },
    methods: {
        valueMapper({ name, value, flag }) {
            const [language /*, locale*/] = value.split('-')
            const mappedValue =
                this.valueRange === VALUE_RANGES.LANG_ONLY ? language : value
            return { name, value: mappedValue, flag }
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.chosenLang = newVal
            },
        },
    },
}
</script>

<style scoped lang="less">
.locale-picker {
    margin-right: 5px;
    color: #fff;
    &__select {
        width: auto;
        margin-left: 5px;
    }

    &__flag {
        width: 25px;
        margin-right: 5px;
        &--small {
            width: 15px;
        }
    }

    &__text {
        padding-right: 8px;
    }
}
</style>
